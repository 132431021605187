import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const LimbusDatabaseCharactersPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters lore</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_6.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Characters lore</h1>
          <h2>List of Sinners available in Limbus Company and their lore.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Characters" />
        <StaticImage
          src="../../../images/limbuscompany/generic/limbus_characters.webp"
          alt="Characters"
        />
        <p>
          The main cast of Limbus Company consists of 12 characters, but with
          how the game works, each of them will have multiple 'identities' that
          change their designs, skills etc. Still, this article will focus only
          on the base characters.
        </p>
        <SectionHeader title="Yi Sang" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_1.jpg"
          alt="Ego 1"
        />
        <h5>About</h5>
        <p>
          Formerly the youngest chief researcher at the [REDACTED] Wing, this
          sinner possesses higher intelligence compared to most others. We
          estimate that everyday conversation with this sinner will prove
          difficult for managers selected by our current screening process.
          Avoiding communication altogether will pose a risk on its own,
          however. If the sinner remains silent for a long time, that means he
          is currently processing a line of deliberate thought; we recommend
          waiting patiently for him.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.1 - E.G.O.: <strong>Crow's Eye View</strong>
          </li>
          <li>Particulars: Ruminating</li>
          <li>
            Yi Sang is a reference to the real Korean poet and writer Yi Sang
            and their short story from 1936, titled “Wings”.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="HsffQ_RfOYQ" />
          </Col>
        </Row>
        <SectionHeader title="Faust" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_2.jpg"
          alt="Ego 2"
        />
        <h5>About</h5>
        <p>
          The sinner who developed the engine of Mephistopheles, the bus
          operated by Limbus Company. She claims that there is no one in the
          City who could rival her library of knowledge and wisdom, and she
          isn't necessarily wrong. You might find her high and mighty attitude
          when she allows you to converse with her displeasing, but as seen with
          how she treats everyone with subtle gestures of arrogance, there is no
          hope of her parting with it, ever. We recommend simply nodding once
          and getting over it. Even though she has an unparalleled genius, we
          can't quite figure out how to use this one. Perhaps you could be the
          one to find out?
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.2 - E.G.O.: <strong>Representation Emitter</strong>
          </li>
          <li>Particulars: Insufferable, Chronic Lassitude</li>
          <li>
            Faust is a reference to the main character of the play “Faust” by
            Johann Wolfgang von Goethe from 1808.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="4zJ8EZU6MXE" />
          </Col>
        </Row>
        <SectionHeader title="Don Quixote" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_3.jpg"
          alt="Ego 3"
        />
        <h5>About</h5>
        <p>
          There is no sinner that can outmatch this one's level of passion. An
          avid aficionada of all things Fixer, she adorns herself with a variety
          of Fixer-related merchandise. They won't affect her performance in
          combat one way or another, so there is no need for you to restrict her
          from keeping her decorations. She is deeply immersed in the role of a
          righteous Fixer, hence the exaggerated mannerisms akin to those of an
          actor. (Has such a thing ever existed, really?) It's advised to play
          along with her for a smooth mission.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.3 - E.G.O.: <strong>The Blood of Sancho</strong>
          </li>
          <li>
            Particulars: Over-passionate, [REDACTED], Has Delusions of Grandeur
          </li>
          <li>
            Don Quixote is a reference to the protagonist of the novel “Don
            Quixote” by Miguel de Cervantes from 1605.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="_7osI0bdrsA" />
          </Col>
        </Row>
        <SectionHeader title="Ryoshu" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_4.jpg"
          alt="Ego 4"
        />
        <h5>About</h5>
        <p>
          As they say, every person has their own creed—and it’s only right to
          respect it as the Executive Manager. But with some creeds, you will
          have difficulty in even simply pretending to sympathize. You will have
          noticed that the sinner in question is a silent chain-smoker whose
          eyes shine with a euphoric gleam only during battle. She believes that
          senselessly destroying the enemy's heads is an uncultured act and a
          step backward for art. If you are at your wit's end, please press the
          red button included in your welcome kit.
        </p>
        <p>
          [CAUTION]: This sinner's background is one that even our company may
          have trouble handling; it is advised against making her breed personal
          resentment towards you.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.4 - E.G.O.: <strong>Forest for the Flames</strong>
          </li>
          <li>Particulars: Has an aesthetic sense, Chain-smoker</li>
          <li>
            Ryoshu is a reference to the short story “Hell Screen” by Ryunosuke
            Akutagawa from 1918.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="PP73VGLCUmg" />
          </Col>
        </Row>
        <SectionHeader title="Meursault" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_5.jpg"
          alt="Ego 5"
        />
        <h5>About</h5>
        <p>
          Wants clear and concise commands, requiring no judgement on his part.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.5 - E.G.O.: <strong>Chains of Others</strong>
          </li>
          <li>Particulars: Refuses to cast any moral judgement</li>
          <li>
            Meursault is a reference to the main character of the novella “The
            Stranger” by Albert Camus from 1942.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="vZcQ7c9-gKE" />
          </Col>
        </Row>
        <SectionHeader title="Hong Lu" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_6.jpg"
          alt="Ego 6"
        />
        <h5>About</h5>
        <p>
          A sinner who had lived an affluent life before becoming our company's
          asset. As such, he has a certain admiration for a free life; but
          occasionally, he may make ignorant and unsavory questions in regards
          to the food cooked by a certain other sinner or other aspects of the
          low-lives' lowly culture. This can cause much irritation for the other
          sinners. However, it is important to note that no sarcastic undertones
          are contained in his curiosity-driven inquiries, so it is ill-advised
          to let them get physical over it.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.6 - E.G.O.: <strong>Land of Illusion</strong>
          </li>
          <li>Particulars: Bachelor, Sheltered</li>
          <li>
            Hong Lu is a reference to the “Dream of the Red Chamber” by Cao
            Xueqin, written during Qing’s dynasty ruling and first printed in
            1791.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="tjDodp8zMHA" />
          </Col>
        </Row>
        <SectionHeader title="Heathcliff" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_7.jpg"
          alt="Ego 7"
        />
        <h5>About</h5>
        <p>
          This sinner prefers to take action rather than wait for logic to
          dictate his course. His thoughts and feelings are blatantly visible in
          his demeanor, and he has no intention of hiding them; his brashness
          may cause headaches (or physical injury leading to a headache) in the
          process of forming a relationship. Please do note that this is not an
          indication of serious damage to the sinner's thought process. If his
          fit of rage persists, please contact the human resources department.
          Once you realize that he is the type that tends to be simple-minded,
          you'll have an easier time handling him.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.7 - E.G.O.: <strong>Bodysack</strong>
          </li>
          <li>
            Particulars: Anger-management Issues, Ruffian, Inferiority Complex
          </li>
          <li>
            Heatcliff is a reference to the protagonist of the novel “Wuthering
            Height” by Emily Bronte, written in 1847.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="-4ve1VE3s0E" />
          </Col>
        </Row>
        <SectionHeader title="Ishmael" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_8.jpg"
          alt="Ego 8"
        />
        <h5>About</h5>
        <p>
          You may be disheartened by the defective state of many of your
          sinners, but do not worry; this sinner is one of the few who can carry
          out most missions without much complaint. She is likely to make
          reasonable judgements, so it is a good idea to start conversations
          while looking her way if you're seeking sound advice. However, you
          mustn't forget: this sinner has survived an absurd voyage in a far and
          wide body of water. Her patience may run deep, but once you take a
          wrong turn with her, it will be a difficult task to regain her trust.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.8 - E.G.O.: <strong>Snagharpoon</strong>
          </li>
          <li>Particulars: Suffers from an obsessive-compulsive neurosis</li>
          <li>
            Ishmael is a reference to the novel “Moby Dick” by Herman Melville
            from 1851.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="Uc9VZdlOfRc" />
          </Col>
        </Row>
        <SectionHeader title="Rodion" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_9.jpg"
          alt="Ego 9"
        />
        <h5>About</h5>
        <p>
          The unfortunate traits of being Backstreets-born are apparent in this
          sinner. She loves money, gambling, and betting—so it is advised to pay
          close attention to her as the manager. She tends to deflect orders
          with an unimpressive wink or a silly joke; we recommend taking a stern
          stance at times to remind her that you are her superior. Her mood may
          be down at irregular intervals. There is nothing you can do to aid her
          in that state, so we recommend waiting for it to pass.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.9 - E.G.O.: <strong>What is cast</strong>
          </li>
          <li>Particulars: Materialistic, Engrossed Gambler</li>
          <li>
            Rodion is a reference to the main character of the Crime and
            Punishment by Fyodor Dostoevsky from 1867.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="3f2OxKLg0KY" />
          </Col>
        </Row>
        <SectionHeader title="Emil Sinclair" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_11.jpg"
          alt="Ego 11"
        />
        <h5>About</h5>
        <p>
          Even with the volatile nature of human growth around his age taken
          into consideration, this sinner seems to get particularly startled or
          frown when spoken to. He isn't used to real combat at the moment,
          meaning that he may struggle to keep himself together at the sight of
          something as mild as spilt innards at first. As there may be those
          with violent tendencies among your crew, we recommend taking an
          approach focused on positive reinforcement with this sinner. He
          appears to possess a murderous gaze that he himself isn't aware of;
          this is a hopeful sign that he has the potential to grow into an
          expert of our company's profession with the right stimuli.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.11 - E.G.O.: <strong>Branch from the Tree of Knowledge</strong>
          </li>
          <li>Particulars: Wishy-washy, Overdependent, Inexorably Insecure</li>
          <li>
            Emil Sinclair is a reference to the main character, Emil Sinclair,
            from the book “Demian” by Hermann Hesse published 1919 (version with
            a prologue was released in 1960).
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="pv11vGknUUo" />
          </Col>
        </Row>
        <SectionHeader title="Outis" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_12.jpg"
          alt="Ego 12"
        />
        <h5>About</h5>
        <p>
          This sinner could serve as an excellent advisor depending on your
          capability. However, she also has a meddlesome tendency, so it is
          recommended to keep her satisfied with short replies of agreement. She
          is an expert when it comes to tactics and various pearls of wisdom
          about life, making her advice all the more valuable for your mission.
          In addition, she possesses a vast knowledge of workshop technologies;
          you could trust her with simple repairs of the bus.
        </p>
        <p>
          [NOTICE]: Unlike the other sinners, accessing records pertaining to
          the past of this sinner is not permitted.
        </p>
        <p>
          [WARNING]: Keep an eye on this sinner and beware that she does not
          exercise her cunning intellect for purposes other than completing our
          company's mission.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.12 - E.G.O.: <strong>Suffering Becomes Experience</strong>
          </li>
          <li>Particulars: Strategic</li>
          <li>
            Outis is a reference to the “Odyssey” by Homer, more particularly to
            the Odysseus who used the word Outis (Greek “Nobody”) as his alias
            during his journey.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="lY613l8FQTI" />
          </Col>
        </Row>
        <SectionHeader title="Gregor" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_13.jpg"
          alt="Ego 13"
        />
        <h5>About</h5>
        <p>
          This sinner is relatively easy to handle in terms of personality.
          However, an upsurge of emotion or a sudden change of environment may
          cause parts or the whole of his body to transform into insectile
          (notably akin to a carapace, resembling that of the _________)
          tissues. If you do not wish to be a rude manager (not that you're
          obligated to be nice), we recommend keeping your facial expression in
          check so that your disgust is not visible to him. He sometimes uses
          cynical language, but he can still be reasoned with, and you can get
          around him without much difficulty as long as you learn the knack of
          it.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.13 - E.G.O.: <strong>Suddenly, One Day</strong>
          </li>
          <li>Particulars: Repulsive, Spontaneously Metamorphice</li>
          <li>
            Gregor is a reference to the main character of the book
            “Metamorphosis” by Franz Kafka from 1912.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="to_4-SeDid8" />
          </Col>
        </Row>
        <SectionHeader title="Dante" />
        <StaticImage
          src="../../../images/limbuscompany/generic/char_ego_10.jpg"
          alt="Ego 10"
        />
        <h5>About</h5>
        <p>
          Dante, supposedly Limbus Company's manager / main character, character
          with a clock instead of the head. They are an enigma so far with
          little details about them. We only know they have the ability to
          rewind time and bring back dead sinners to life.
        </p>
        <h5>Other information</h5>
        <ul>
          <li>
            No.10 - E.G.O.: <strong>???</strong>
          </li>
          <li>Particulars: ???</li>
          <li>
            Dante is most likely a reference to the “Divine Comedy” by Dante
            Alighieri from 1320.
          </li>
        </ul>
        <h5>Introduction video</h5>
        <p>Missing.</p>
      </div>
    </DashboardLayout>
  );
};

export default LimbusDatabaseCharactersPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters | Limbus Company | Prydwen Institute"
    description="List of Sinners available in Limbus Company and their lore."
  />
);
